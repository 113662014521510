<template>
    <div>

        <!-- 标题 start -->
        <div class="page_title">
            <i class="el-icon-arrow-left" style="cursor: pointer;" @click="back()"></i>
            {{$i18n.t('firmwareMsg.text1')}}
        </div>
        <!-- 标题 end -->

        <div class="table_card">
            <el-descriptions :column="3" :title="`${$i18n.t('firmwareMsg.text2')}${msg.firmwareModel}`">
                <el-descriptions-item :label="$i18n.t('firmwareMsg.text3')">{{msg.version}}</el-descriptions-item>
                <el-descriptions-item :label="$i18n.t('firmwareMsg.text4')">{{msg.pid}}</el-descriptions-item>
                <el-descriptions-item :label="$i18n.t('firmwareMsg.text5')">{{msg.createTime}}</el-descriptions-item>
                <el-descriptions-item label="MD5">
                    <span class="notes" :title="splicMd5()">
                        <span  v-for="(item, index) in urls" :key="index">
                            <span v-if="index > 0">,</span>{{item.md5}}
                        </span>
                    </span>
                </el-descriptions-item>
                <el-descriptions-item :label="$i18n.t('firmwareMsg.text6')">{{$config.getMsgItemUtil($message.firmwareTypeList, msg.firmwareType, 'value', 'text')}}</el-descriptions-item>
                <el-descriptions-item :labelStyle="{opacity: 0}"></el-descriptions-item>
                <el-descriptions-item v-for="(item, index) in urls" :key="index" :label="$i18n.t('firmwareMsg.text7')" :labelStyle="{marginTop: index > 0 ? '-20px' : '-10px'}" :contentStyle="{marginTop: index > 0 ? '-20px' : '-10px'}" :span="5">
                    <span v-if="!item.isEdit">
                        <span :id="`url${index}`">{{item.url}}</span>
                        <el-button type="text" style="margin-left: 10px;" :class="`copy${index}`" :data-clipboard-target="`#url${index}`">{{$i18n.t('firmwareMsg.text8')}}</el-button>
                        <el-button type="text" @click="item.isEdit = !item.isEdit;">{{$i18n.t('firmwareMsg.text9')}}</el-button>
                    </span>
                    <span v-else>
                        <el-input size="mini" class="url_input" :placeholder="$i18n.t('firmwareMsg.text12')" v-model="item.url"/>
                        <el-button type="text" style="margin-left: 10px;" v-if="!item.editLoading" @click="cancel(item, index)">{{$i18n.t('firmwareMsg.text10')}}</el-button>
                        <el-button type="text" :loading="item.editLoading" @click="modifyUrl(item, index)">{{$i18n.t('firmwareMsg.text11')}}</el-button>
                    </span>
                </el-descriptions-item>

            </el-descriptions>
            <div class="set_update">
                <el-descriptions class="left_form" :column="3" :title="$i18n.t('firmwareMsg.text13')">
                    <el-descriptions-item :label="$i18n.t('firmwareMsg.text14')">{{$config.getMsgItemUtil($message.installTypeList, msg.installType, 'value', 'label')}}</el-descriptions-item>
                    <el-descriptions-item :label="$i18n.t('firmwareMsg.text15')">{{$config.getMsgItemUtil($message.triggerModeList, msg.triggerType, 'value', 'label')}}</el-descriptions-item>
                    <el-descriptions-item :label="$i18n.t('firmwareMsg.text16')">{{msg.reboot ? $i18n.t('firmwareMsg.text17') : $i18n.t('firmwareMsg.text18')}}</el-descriptions-item>
                </el-descriptions>
            </div>
            <div class="set_update" style="margin-top: 10px;">
                <el-descriptions class="left_form" :column="4" :title="$i18n.t('firmwareMsg.text19')">
                    <el-descriptions-item :label="$i18n.t('firmwareMsg.text20')">{{$config.getMsgItemUtil($message.publishStatusList, msg.publishStatus, 'value', 'text')}}</el-descriptions-item>
                    <el-descriptions-item :label="$i18n.t('firmwareMsg.text21')">{{msg.publishTime ? msg.publishTime : '--'}}</el-descriptions-item>
                    <el-descriptions-item :label="$i18n.t('firmwareMsg.text22')" :span="2">
                        <span class="notes" style="width: 300px" :title="msg.macs">
                            {{msg.macs ? msg.macs : '--'}}
                        </span>
                    </el-descriptions-item>
                </el-descriptions>
            </div>
        </div>

        <div class="from_card">
            <div class="title">{{$i18n.t('firmwareMsg.text23')}}</div>

            <el-input size="small" style="width: 300px;margin-top: 20px;" :placeholder="$i18n.t('firmwareMsg.text24')" v-model="deviceId" class="input-with-select">
                <el-button slot="append" icon="el-icon-search" @click="getOTALogPager(1)"></el-button>
            </el-input>
            <div class="card_list">
                <div class="card_item">
                    <div class="statistics_card_top">{{$i18n.t('firmwareMsg.text25')}}</div>
                    <div class="statistics_card_center">{{otaStatisticInfo.totalDeviceCount}}</div>
                    <div class="statistics_card_bottom">
                        <span style="color: rgba(0, 0, 0, 0.3)">{{$i18n.t('firmwareMsg.text29')}}</span>{{otaStatisticInfo.totalDeviceCount > 0 ? '100%' : '0%'}}
                    </div>
                </div>
                <div class="card_item">
                    <div class="statistics_card_top">{{$i18n.t('firmwareMsg.text26')}}</div>
                    <div class="statistics_card_center">{{otaStatisticInfo.successCount}}</div>
                    <div class="statistics_card_bottom">
                        <span style="color: rgba(0, 0, 0, 0.3)">{{$i18n.t('firmwareMsg.text29')}}</span>{{otaStatisticInfo.totalDeviceCount > 0 ? `${(otaStatisticInfo.successCount/otaStatisticInfo.totalDeviceCount).toFixed(4)*100}%` : '0%'}}
                    </div>
                </div>
                <div class="card_item">
                    <div class="statistics_card_top">{{$i18n.t('firmwareMsg.text27')}}</div>
                    <div class="statistics_card_center">{{otaStatisticInfo.failedCount}}</div>
                    <div class="statistics_card_bottom">
                        <span style="color: rgba(0, 0, 0, 0.3)">{{$i18n.t('firmwareMsg.text29')}}</span>{{otaStatisticInfo.totalDeviceCount > 0 ? `${(otaStatisticInfo.failedCount/otaStatisticInfo.totalDeviceCount).toFixed(4)*100}%` : '0%'}}
                    </div>
                </div>
                <div class="card_item">
                    <div class="statistics_card_top">{{$i18n.t('firmwareMsg.text28')}}</div>
                    <div class="statistics_card_center">{{otaStatisticInfo.upgradingCount}}</div>
                    <div class="statistics_card_bottom">
                        <span style="color: rgba(0, 0, 0, 0.3)">{{$i18n.t('firmwareMsg.text29')}}</span>{{otaStatisticInfo.totalDeviceCount > 0 ? `${(otaStatisticInfo.upgradingCount/otaStatisticInfo.totalDeviceCount).toFixed(4)*100}%` : '0%'}}
                    </div>
                </div>
                <div class="card_item">
                    <div class="statistics_card_top">{{$i18n.t('firmwareMsg.text30')}}</div>
                    <div class="statistics_card_center">{{otaStatisticInfo.noUpgradingCount}}</div>
                    <div class="statistics_card_bottom">
                        <span style="color: rgba(0, 0, 0, 0.3)">{{$i18n.t('firmwareMsg.text29')}}</span>{{otaStatisticInfo.totalDeviceCount > 0 ? `${(otaStatisticInfo.noUpgradingCount/otaStatisticInfo.totalDeviceCount).toFixed(4)*100}%` : '0%'}}
                    </div>
                </div>
            </div>

            <el-table
                ref="deviceTable"
                class="device_table"
                :data="deviceMsg.list"
                :header-cell-style="{background:'#eef1f6',color:'rgba(0, 0, 0 ,.85)',fontSize:'14px', paddingLeft: '24px'}"
                :cell-style="{color:'rgba(0, 0, 0 ,.65)', padding: '16px 24px'}"
                v-loading="loading"
                style="width: 100%;">
                <el-table-column
                    width="400"
                    prop="deviceId"
                    :label="$i18n.t('firmwareMsg.text31')">
                </el-table-column>
                <el-table-column
                    :label="$i18n.t('firmwareMsg.text32')">
                    <template slot-scope="s">
                        <span style="color: rgb(224, 32, 32);" v-if="s.row.isOnline === 'N'">{{$i18n.t('firmwareMsg.text33')}}</span>
                        <span style="color: rgb(109, 212, 1);" v-else>{{$i18n.t('firmwareMsg.text34')}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="currentVersion"
                    :label="$i18n.t('firmwareMsg.text35')">
                </el-table-column>
                <el-table-column
                    :label="$i18n.t('firmwareMsg.text36')">
                    <template slot-scope="s">
                        <span :style="{color: s.row.status === 4? 'rgb(109, 212, 1)' : ''}">
                            {{$config.getMsgItemUtil($message.upgradeStatusList, s.row.status, 'value', 'label')}}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column
                    min-width="130"
                    prop="finishedUpgradeTime"
                    :label="$i18n.t('firmwareMsg.text37')">
                </el-table-column>
            </el-table>
            <el-pagination
                background
                @current-change="handleCurrentChange"
                :page-size="10"
                :current-page="page"
                layout="prev, pager, next"
                style="margin-top: 10px;text-align: right;"
                :total="parseInt(deviceMsg.totalRecords)">
            </el-pagination>
        </div>

    </div>
</template>

<script>
import {Pie, Column} from '@antv/g2plot';
import {mapActions} from "vuex";
import Clipboard from "clipboard";

export default {

    name: "firmwareMsg",

    data() {

        return {
            deviceId: "",
            isEdit: false,
            deviceMsg: {
                list: [],
                totalRecords: 10
            },
            loading: false,
            editLoading: false,
            page: 1,
            msg: {},
            urls: [],
            otaStatisticInfo: {
                "totalDeviceCount": "0",
                "successCount": "0",
                "failedCount": "0",
                "upgradingCount": "0",
                "noUpgradingCount": "0"
            },
            clipboardList: []
        }

    },

    mounted() {
        this.getOtaFileDetail({
            otaFileCode: this.$route.query.code
        }).then(res => {
            this.msg = res.result;
            if (res.result.hasChannel) {
                let urls = [];
                res.result.channelList.forEach((item, index) => {
                    urls.push({
                        url: item.downUrl ? item.downUrl : item.fileUrl,
                        url1: item.downUrl ? item.downUrl : item.fileUrl,
                        isEdit: false,
                        editLoading: false,
                        code: item.code,
                        md5: item.md5Sign
                    })
                    let clipboard = new Clipboard('.copy'+index).on('success', (e) => {
                        this.$dialog.showMessage(this.$i18n.t('product.text13'), this.$config.TOAST_SUCCESS);
                    });
                    this.clipboardList.push(clipboard);
                })
                this.urls = urls;
            } else {
                this.urls = [{
                    url: res.result.downUrl ? res.result.downUrl : res.result.srcFileUrl,
                    url1: res.result.downUrl ? res.result.downUrl : res.result.srcFileUrl,
                    isEdit: false,
                    editLoading: false,
                    md5: '--'
                }]
                let clipboard = new Clipboard('.copy0').on('success', (e) => {
                    this.$dialog.showMessage(this.$i18n.t('product.text13'), this.$config.TOAST_SUCCESS);
                });
                this.clipboardList.push(clipboard);
            }
        }, err => {
            this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
        })

        this.otaStatistic({
            otaFileCode: this.$route.query.code
        }).then(res => {
            this.otaStatisticInfo = res.result;
        }, err => {
            this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
        })
        this.getOTALogPager(1);
    },

    methods: {

        ...mapActions('maintain', ['getOtaFileDetail', 'otaStatistic', 'queryOTALogPager', 'modifyDownloadUrl']),

        splicMd5() {
            let md5 = '';
            this.urls.forEach((item, index) => {
                md5 = md5 + (index > 0 ? ',' : '') + item.md5
            })
            return md5;
        },

        getOTALogPager(page) {
            this.loading = true;
            if (page) this.page = page;
            this.queryOTALogPager({
                deviceId: this.deviceId,
                currPage: this.page,
                pageSize: 10,
                otaFileCode: this.$route.query.code
            }).then(res => {
                this.deviceMsg.list = res.result.list;
                this.deviceMsg.totalRecords = res.result.totalRecords;
            }, err => {
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            }).finally(() => this.loading = false)
        },

        modifyUrl(item, index) {
            this.urls[index].editLoading = true;
            let data = {
                fileUrl: this.urls[index].url,
                otaFileCode: this.$route.query.code
            }
            if (item.code) data.channelCode = item.code
            this.modifyDownloadUrl(data).then(res => {
                this.$dialog.showMessage(this.$i18n.t('firmwareMsg.text38'), this.$config.TOAST_SUCCESS);
                this.urls[index].isEdit = false;
                this.urls[index].url1 = this.urls[index].url;
            }, err => {
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            }).finally(() => this.urls[index].editLoading = false)
        },

        cancel(item, index) {
            setTimeout(() => {
                this.urls[index].isEdit = !item.isEdit;
                this.urls[index].url = this.urls[index].url1
            }, 100)
        },

        /**
         * 返回上一页
         */
        back() {
            this.$router.back();
        },

        handleCurrentChange(page) {
            this.getOTALogPager(page);
        }

    },

    destroyed() {
        if (this.clipboardList.length > 0) {
            this.clipboardList.forEach(item => item.destroy())
            this.clipboardList = [];
        }
    }

}
</script>

<style scoped>

.table_card {
    background: #FFFFFF;
    border-radius: 8px;
    position: relative;
    margin: 64px 24px 20px 24px;
    box-shadow: 0 0 4px 0 #BCBCBC;
    padding: 20px;
    text-align: left;
    animation: fadeInDown; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 1s; /* don't forget to set a duration! */
}

/deep/ .el-descriptions-item__container {
    align-items: center;
    /*margin-top: -10px;*/
}

.set_update {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.from_card {
    background: #FFFFFF;
    border-radius: 8px;
    position: relative;
    margin: 0 24px 20px 24px;
    box-shadow: 0 0 4px 0 #BCBCBC;
    padding: 20px;
    text-align: left;
    animation: fadeInUp; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 1s; /* don't forget to set a duration! */
}

.title {
    font-size: 16px;
    color: rgb(51, 51, 51);
    font-weight: bold;
}

.card_list {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.card_item {
    width: 18%;
    border: 1px solid #c1c1c1;
    border-radius: 8px;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.statistics_card_top {
    font-size: 14px;
    color: rgb(51, 51, 51);
}

.statistics_card_center {
    font-size: 24px;
    color: rgb(51, 51, 51);
    font-weight: bold;
    margin-top: 10px;
}

.statistics_card_bottom {
    font-size: 12px;
    margin-top: 10px;
    color: rgba(0, 0, 0, 0.8);
}

.device_table {
    margin-top: 20px;
}

.url_input {
    width: 600px;
}

.notes {
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
}
</style>
